import * as Sentry from "@sentry/vue";

export default {
  methods: {
    showResponseToUser(type, message) {
      this.messageType = type;
      this.message = message;
    },
    sendData(saveFinalConfirmedData, pageDisabledKey, ignoredKeys = []) {
      const emptyFieldExist = Object.entries(this.formData).some(
        (currentFormDataElement) => {
          if (!ignoredKeys.includes(currentFormDataElement[0])) {
            if (
              currentFormDataElement[0].startsWith("_vj_advertisement_") &&
              !this.formData[
                `_vj_offer_url_checkbox_${currentFormDataElement[0].replace(
                  "_vj_advertisement_",
                  ""
                )}`
              ]
            ) {
              return false;
            }
            return (
              currentFormDataElement[1] === null ||
              currentFormDataElement[1] === "" ||
              (currentFormDataElement[1] !== undefined &&
                currentFormDataElement[1].length < 1)
            );
          } else {
            return false;
          }
        }
      );

      if (saveFinalConfirmedData) {
        if (emptyFieldExist) {
          this.showResponseToUser(
            "error",
            "Zanim zapiszesz wersję ostateczną uzupełnij wszystkie informacje!"
          );
        } else {
          this.formData[pageDisabledKey] = true;
          this.disabled = true;

          this.dispatchDataToServer(true);
        }
      } else {
        this.dispatchDataToServer(false);
      }
    },
    dispatchDataToServer(confirmed) {
      this.loading = true;
      const pageConfirmed = confirmed ? 1 : 0;

      const dataToDispatch = {
        data: this.formData,
        confirmed: pageConfirmed,
      };

      this.$store
        .dispatch("sendBulkData", dataToDispatch)
        .then((response) => {
          this.loading = false;
          if (response.status === 200) {
            this.showResponseToUser("ok", "Zapisano zmiany w formularzu…");
          } else {
            this.showResponseToUser(
              "error",
              `Wystąpił błąd, nie udało się zapisać zmian: ${response.statusText}`
            );
            Sentry.captureException(response);
          }
        })
        .catch((error) => {
          this.showResponseToUser(
            "error",
            `Wystąpił błąd, nie udało się zapisać zmian: ${error.message}`
          );
          Sentry.captureException(error);
        });
    },
  },
};
