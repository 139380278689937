import { mapActions } from 'vuex';

export default {
  data() {
    return {
      wordDownloadLink: null,
    }
  },
  methods: {
    ...mapActions([
      'exportDataToWord',
    ]),
    setWordDownloadLink(keys, english) {
      this.exportDataToWord({ keys, english }).then((response) => {
        this.wordDownloadLink = response;
      })
    },
  }
}