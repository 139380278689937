export default {
  data() {
    return {
      maxCharacters: 3000,
      charactersLeft: 0,
      contentLength: {},
    }
  },
  methods: {
    recalculateContentLength() {
      let sumLength = 0;

      for (const key in this.contentLength) {
        if (this.contentLength.hasOwnProperty(key)) {
          sumLength += this.contentLength[key];
        }
      }
      this.charactersLeft = this.maxCharacters - sumLength;
    },
    assignContentLength(e) {
      this.contentLength[e.name] = e.length;
      this.recalculateContentLength();
    },
  },
  computed: {
    saveFinalDataText() {
      return this.charactersLeft >= 0 ? 'Zapisz wersję ostateczną' : 'Zmniejsz liczbę znaków w tekście!';
    },
  },
}