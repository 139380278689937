import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'activeEdition',
      'namedDetails',
    ]),
  },
  methods: {
    checkIfDetailIsNotNull(detailObject) {
      if (detailObject) {
        return detailObject.value !== '' && detailObject.value !== null && detailObject.value !== 'null';
      }
    },
    transformDetailValueToBoolean(detailValue) {
      return (detailValue === 'true' || detailValue === 'false') ? JSON.parse(detailValue) : detailValue;
    },
    splitStringWrittenArrayToRealArray(detailValue, mapDetailToNumber) {
      detailValue = detailValue.split(',');

      if (mapDetailToNumber) {
        detailValue = detailValue.map(Number);
      }

      return detailValue;
    },
    assingData(key, defaultValue, expectedType, mapDetailToNumber) {
      const apiDetail = this.namedDetails[this.activeEdition + key];

      let detail = this.checkIfDetailIsNotNull(apiDetail) ? apiDetail.value : defaultValue;
      
      if (expectedType === 'boolean') {
        detail = this.transformDetailValueToBoolean(detail);
      } else if (expectedType === 'array' && typeof detail === 'string') {
        detail = this.splitStringWrittenArrayToRealArray(detail, mapDetailToNumber);
      }

      this.formData[key] = detail;
    },
  }
}